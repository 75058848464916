import { Component, OnInit } from '@angular/core';
import { faSort, faSortUp, faSortDown, faSearch, faUserPlus, faUserEdit, faFilePdf, faFileExcel, faRedo } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { Pagination } from '../_class/pagination';
import { AuthService } from '../_service/auth.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { AraucocurricularService } from '../_service/araucocurricular.service';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { Ficha } from '../_class/ficha';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  constructor(
    private sessionService: AuthService,
    private toastService: ToastrService,
    private AraucocurricularService: AraucocurricularService ,
    private araucoService: AraucocurricularService
  ) { }

  page: Pagination = new Pagination({
    page: 1,
    perPage: 10,
    sort: '-fic_id',
    expand: ['trabajador', 'params']
  });
  subLogin: Subscription;
  toastrRef;

  ngOnInit() {
    if (this.sessionService.isValid) {
       this.getFichas();
    }

    this.subLogin = this.sessionService.onLoginSuccess.subscribe(() => {
       this.getFichas();
    });
  }

  ngOnDestroy() {
    this.subLogin.unsubscribe();
  }

  icon = {
    userPlus: faUserPlus,
    userEdit: faUserEdit,
    search: faSearch,
    filePdf: faFilePdf,
    fileExcel: faFileExcel,
    redo: faRedo
  };

  async downloadExcel() {
    try {
      const toastrRef = this.toastService.info('Generando Reporte masivo...', 'REPORTE');
      const response = await this.AraucocurricularService.GetReporteMasivo().toPromise()
      const date = moment().format('DD-MM-YYYY hh.mm.ss')
      FileSaver.saveAs(new Blob([response]), `Reporte Arauco - BDCurricular - RV ${date}.csv`)
      this.toastService.remove(this.toastrRef)
      this.toastService.success("Se ha descargado la planilla")
    } catch (error) {
      this.toastService.remove(this.toastrRef)
      this.toastService.warning('Ha ocurrido un problema, verifique la conexión a internet', error.message)
    }
  }

  sort = {
    name: 'fic_id',
    order: false,
    menu: [
      { sort: 'ficId', title: 'Ficha' },
      { sort: 'traRut', title: 'RUT' },
      { sort: 'params.data.dec_correcto', title: 'CANT PERCEPCION'},
      { sort: 'params.data.dec_total', title: 'PERCEPCION TOTAL' },
      { sort: 'params.data.dec_nota', title: 'PERCEPCION NOTA ' },
      { sort: 'params.data.con_correcto', title: 'CANT CONDUCTUAL' },
      { sort: 'params.data.con_total', title: 'CONDUCTUAL TOTAL' },
      { sort: 'params.data.con_nota', title: 'CONDUCTUAL NOTA' },
      { sort: 'params.data.nota', title: 'NOTA' }
    ]
  };

  fichas: Ficha[] = [];

  getFichas(){
    this.page.page = 1;
    this.page.perPage = 10;
    this.AraucocurricularService.getFichas(this.page.params)
      .subscribe(data=>{
        this.fichas=data.body
        this.page.Load(data.headers);
      })
  }
  setOrder(prop: string) {
    if (this.sort.name != prop) {
      this.sort.name = prop;
      this.sort.order = true;
    } else {
      this.sort.order = !this.sort.order;
    }
    this.page.page = 1;
    this.page.sort = this.sort.order ? this.sort.name : '-' + this.sort.name
    this.getFichas()
  }

  iconOrder(prop: string) {
    if (this.sort.name == prop) {
      return this.sort.order ? faSortDown : faSortUp;
    } else {
      return faSort;
    }
  }

  

  async onScroll() {
    if (this.fichas.length < this.page.total) {
      this.page.page++;
      const { body, headers } = await this.AraucocurricularService.getFichas(this.page.params).toPromise();
      this.page.Load(headers);
      this.fichas.push(...body);
    }
  }

}