<nav class="navbar navbar-expand-lg navbar-dark bg-primary d-flex">
  <a class="navbar-brand" >
    <img src="/assets/logo-arauco.png" alt="logo-arauco" width="120">
  </a>
  <span class="navbar-text">
    Arauco BDCurricular - RV
  </span>
  <!-- <a class="navbar-brand" [routerLink]="[]"><small>Simulador Operación Carro Madereo Grapple</small></a> -->
  <button class="navbar-toggler" type="button" (click)="isCollapsed=
        !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapse">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-start" id="navbarNav" [ngbCollapse]="!isCollapsed">
    <!-- <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="/reportes" routerLinkActive="active">Reportes</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/inscripciones" routerLinkActive="active">Inscripciones</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">Dashboard</a>
      </li>
    </ul> -->
  </div>
  <div class="collapse navbar-collapse justify-content-end" id="navbarNav" [ngbCollapse]="!isCollapsed">

    <ul class="navbar-nav">
      <li class="mr-2">
        <a class="text-light" style="cursor: context-menu;">Bienvenido {{username}}</a>
      </li>
      <li class="nav-item">
        <a class="text-light" style="cursor:pointer" (click)="logout()">Cerrar Sesión</a>
      </li>
    </ul>
  </div>
</nav>