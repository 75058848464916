<div class="container-fluid text-right">
    <button class="btn btn-success" (click)="downloadExcel()">
        <fa-icon [icon]="icon.fileExcel"></fa-icon>
        Descargar Exel
    </button>
</div><br>
<div class="row mb-3" infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScroll()">
    <div class="col">
        <div class="table-responsive">
            <table class="table table-hover table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col" *ngFor="let item of sort.menu"
                            [ngClass]="{ 'text-primary': sort.name == item.sort }" [ngStyle]="{ cursor: 'pointer'}"
                            (click)="setOrder(item.sort)">
                            <fa-icon [icon]="iconOrder(item.sort)"></fa-icon> {{item.title}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of fichas let i=index">
                        <td>{{i+1}}</td>
                <td>{{item.fic_id}}</td>
                <td>{{item.trabajador.rut}}</td>
                <td >{{item.params.data.decCorrectos}}</td>
                <td>{{item.params.data.decTotal}}</td>
                <td style="font-weight: bold;">{{item.params.data.dec_nota}}</td>
                <td>{{item.params.data.conCorrectos}}</td>
                <td>{{item.params.data.conTotal}}</td>
                <td style="font-weight: bold;">{{item.params.data.con_nota}}</td>
                <td style="font-weight: bold;">{{item.params.data.nota}}</td>
                        <!-- <td>
                            <button class="btn btn-sm btn-primary mr-2" (click)="open(i,item)">
                                <fa-icon [icon]="icon.userEdit"></fa-icon>
                            </button>
                            <button class="btn btn-sm btn-danger" (click)="downloadPDF(item)">
                                <fa-icon [icon]="icon.filePdf"></fa-icon>
                            </button>
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-btn-scroll></app-btn-scroll>