import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './page/login/login.component';
import { BtnScrollComponent } from './page/btn-scroll/btn-scroll.component';
import { MainLayoutComponent } from './page/main-layout/main-layout.component';
import { NavbarComponent } from './page/navbar/navbar.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { InicioComponent } from './inicio/inicio.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbCollapseModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { registerLocaleData } from '@angular/common';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

import localeCL from '@angular/common/locales/es-CL';
registerLocaleData(localeCL, 'es-CL');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BtnScrollComponent,
    MainLayoutComponent,
    NavbarComponent,
    NotFoundComponent,
    InicioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    NgbCollapseModule,
    NgbModalModule,
    Ng2GoogleChartsModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true
    }),
    NgSelectModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-CL' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
