import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Ficha } from '../_class/ficha';

@Injectable({
  providedIn: 'root'
})
export class AraucocurricularService {

  private base = `${environment.endpoint}/v1/transversal`;

  constructor(private http: HttpClient, private auth: AuthService) { }

  GetReporteMasivo(){
    let params= new HttpParams();
    params = params.set(`modulo`, "AraucoCurricular");
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get(`${this.base}/reportemasivo`, { headers, params, responseType:'arraybuffer' });
  }

  public getFichas(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(this.base, { headers, params, observe: 'response' });
  }
}