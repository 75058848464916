import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_service/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isCollapsed = false;

  username = null;

  constructor(
    private session: AuthService
  ) { }

  ngOnInit(): void {
    if (this.session.isValid) {
      const scope = this.session.getScope();
      this.username = scope.name;
    }
    this.session.onLoginSuccess.subscribe(data => {
      this.username = data.name;
    });
  }

  logout() {
    this.session.Logout();
  }

}
